
















































































































































.wordMap {
    .content {
        .title {
            color: #333333;
            font-size: 0.34rem;
            margin-top: 0.18rem;
            margin-bottom: 0.6rem;
            font-weight: bold;
            text-align: center;
        }

    }

    .swiperContent {
        display: flex;
        flex-wrap: wrap;
        margin: 0.5rem 0;

        @media (max-width:767px) {
            margin: 0;
        }

        .item {
            width: 4rem;
            padding: 0.15rem 0.25rem;
            // margin: 0 0.4rem 0 0;
            background: url('../../assets/contextBg.png');
            border-radius: 0.1rem;
            background-size: 100% 100%;

            &:nth-child(3n) {
                margin-right: 0;
            }

            @media (max-width: 767px) {
                width: 100%;

                &,
                &:nth-child(3n) {
                    margin: 0;
                }

                &:nth-child(2n) {
                    margin-right: 0;
                }
            }

            .name {
                color: #333333;
                font-size: 0.18rem;
                margin: 0.1rem 0;
                font-weight: bold;
            }

            .title {
                color: #333333;
                font-size: 0.18rem;
                margin-bottom: 0.1rem;
                font-weight: bold;
				@media (max-width:767px) {
				    font-size: 0.4rem;
					margin-bottom: 0.23rem;
				}
            }

            .line {
                background-color: #333333;
                width: 0.5rem;
                height: 0.05rem;
                margin: 0.15rem 0;
            }

            .text {
                color: #333333;
                font-size: 0.18rem;
                margin-bottom: 0.1rem;
				@media (max-width:767px) {
				    font-size: 0.38rem;
				}

                img {
                    width: 0.22rem;
                    position: relative;
                    top: 0.03rem;
                    margin-right: 0.1rem;
					@media (max-width:767px) {
					    width: 0.4rem;
					}
                }
            }
        }

    }

    .wCenter .swiper-container {
        margin: 0 1rem;
        position: static;
    }

    .wCenter {
        position: relative;
    }

    .swiper-wrapper {
        display: flex;
        align-items: center;
    }

}
